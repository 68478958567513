import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxCharacters'
})
export class MaxCharactersPipe implements PipeTransform {

  transform(value: any, maxLength: number): any {
    if (value == null || value == undefined) {
      return value ;
    } else {
      return value.substr(0, maxLength) + '...';
    }
  }

}
