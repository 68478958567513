import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'invoiceDateFormat'
})
export class InvoiceDateFormatPipe implements PipeTransform {

    transform(value: any, args?: any): String {
        if (value == null) {
            return null;
        };
        return moment(value).format('MM/DD/YYYY');
    }

}
