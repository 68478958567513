import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { Http } from "@angular/http";
import { UserInfoModel } from "../models/user-info-model";
import * as moment from 'moment';

@Injectable({
    providedIn: "root"
})
export class AuthService {
    a = moment().add(30, 'seconds');
    url = environment.apiUrl + "auth/";
    constructor(private _Http: Http, private _Router: Router
        , private _HttpClient: HttpClient) { }

    login(username, password) {
        return this._Http.post(this.url + 'login', { username, password })
            .pipe(map((res: any) => {
                var response = res.json();
                localStorage.setItem('token', response.token);
                localStorage.setItem('userInfo', JSON.stringify(response.userInfo));
                localStorage.setItem('validTo', response.validTo);
                return res;
            }));
    }

    changePassword(password) {
        return this._HttpClient.put(this.url + `changePassword/${password}`, null);
    }

    getAuthInfo() {
        if (localStorage.getItem('userInfo') != null) {
            let validDate = moment(localStorage.getItem('validTo'));
            if (moment().isAfter(validDate)) {
                var loginInfo: any = localStorage.getItem('loginInfo');
                localStorage.clear();
                localStorage.setItem('loginInfo', loginInfo);
                this._Router.navigate(['/login'], { queryParams: { error: 'Session is expired' } });
            }
            else {
                return {
                    userInfo: JSON.parse(localStorage.getItem('userInfo')) as UserInfoModel,
                    token: localStorage.getItem('token')
                };
            }

        }
        else {
            this.logout();
        }
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('validTo');
        sessionStorage.removeItem('OpenCustomers');
        this._Router.navigateByUrl('/login');
    }
}
