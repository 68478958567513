import { Injectable } from "@angular/core";

import {
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";

import { Router } from "@angular/router";

import { Observable, throwError } from "rxjs";

import { tap, map, catchError, finalize } from "rxjs/operators";
import { AuthService } from "../shared/services/auth.service";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class ClientHttpInterceptor implements HttpInterceptor {
  constructor(private _AuthService: AuthService ,
    private _NgxSpinnerService : NgxSpinnerService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // modify request

    var authInfo = this._AuthService.getAuthInfo();
    const tenantId: string = authInfo.userInfo.tenantId == null ? '' : authInfo.userInfo.tenantId;
    const token: string = `Bearer ${authInfo.token}`;

    request = request.clone({
      setHeaders: {
        'Authorization': token,
        'TenantId': tenantId
      }
    });

    if (request.headers.get('isUpload') == null) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
        }
      });
    }
    
    if (request.headers.get('disableLoader') != 'true') {
      this._NgxSpinnerService.show();
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
          return event;
      }),  catchError((error: HttpErrorResponse) => {
        this._NgxSpinnerService.hide();
        return throwError(error);
      }), finalize(() => {
        this._NgxSpinnerService.hide();
      }));

  }
}
