import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortList'
})
export class SortListPipe implements PipeTransform {

  transform(items: any[] , propName?: any): any {
    var arr = items;

    if(arr.length > 0 || arr != null)
    {
      let direction = propName[0][0];
      let column = propName.replace('-','');
      arr.sort((a: any, b: any) => {
              let left = Number(new Date(a[column]));
              let right = Number(new Date(b[column]));
              return (direction === "-") ? right - left : left - right;
      });
      return arr;
    }

    return arr;
  }

}
