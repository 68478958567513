import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCustomerFolders'
})
export class FilterCustomerFoldersPipe implements PipeTransform {

  transform(items: any[], parentId: any): any {
    var fileArr: any = items;

    fileArr = fileArr.filter(x => x.parent == parentId);

    return fileArr;
  }

}
