import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { UserService } from './services/user.service';
import { RoleService } from './services/role.service';
import { HttpModule } from '@angular/http';
import { AuthGuard } from './guards/auth.guard';
import { LoggerService } from './services/logger.service';
import { LookupService } from './services/lookup.service';
import { PhoneService } from './services/phone.service';
import { CreditCardNumberPipe } from './pipes/credit-card-number.pipe';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { FilterCustomerFoldersPipe } from './pipes/filter-customer-folders.pipe';
import { SortListPipe } from './pipes/sort-list.pipe';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { ConfirmationDialogPopupComponent } from './components/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { InvoiceDateFormatPipe } from './pipes/invoice-date-format.pipe';
import { DecimalCurrencyFormatPipe } from './pipes/decimal-currency-format.pipe';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MaxCharactersPipe } from './pipes/max-characters.pipe';
import { ShowedRecordsPaginationDropdownComponent } from './components/showed-records-pagination-dropdown/showed-records-pagination-dropdown.component';
import { FormsModule } from '@angular/forms';
import { ShowedAllRecordsPaginationDropdownComponent } from './components/showed-all-records-pagination-dropdown/showed-all-records-pagination-dropdown.component';

@NgModule({
  imports: [
    CommonModule , FormsModule  ,HttpClientModule ,HttpModule,NgxSmartModalModule.forChild(), NgbModule
  ],
  declarations: [CreditCardNumberPipe, DateFormatPipe, CurrencyFormatPipe, FilterCustomerFoldersPipe,
     SortListPipe, PhoneFormatPipe, ConfirmationDialogPopupComponent, InvoiceDateFormatPipe, DecimalCurrencyFormatPipe
     , MaxCharactersPipe, ShowedRecordsPaginationDropdownComponent, ShowedAllRecordsPaginationDropdownComponent ],
  providers:[NgbActiveModal],
  exports:[CreditCardNumberPipe ,DateFormatPipe, CurrencyFormatPipe, FilterCustomerFoldersPipe ,
    SortListPipe ,PhoneFormatPipe ,ConfirmationDialogPopupComponent, InvoiceDateFormatPipe,
     DecimalCurrencyFormatPipe,MaxCharactersPipe,ShowedRecordsPaginationDropdownComponent,ShowedAllRecordsPaginationDropdownComponent, NgbModule],
    entryComponents: [ConfirmationDialogPopupComponent ,ShowedRecordsPaginationDropdownComponent,ShowedAllRecordsPaginationDropdownComponent]
})
export class SharedModule { }
