import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalCurrencyFormat'
})
export class DecimalCurrencyFormatPipe implements PipeTransform {

  transform(value: any, args?: any): String {
      return isNaN(value)  ?  `$0` : `$${Math.round (value * 10) / 10}`;
  }
}
